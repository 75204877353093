<script lang="ts">
import { Component, Mixins, Prop } from "vue-property-decorator";
import CustomerFieldPreview from "@/modules/customers/components/CustomerFieldPreview.vue";
import CustomerFormDialog from "@/modules/customers/components/CustomerFormDialog.vue";
import FirmPreviewDialog from "@/modules/companies/components/FirmPreviewDialog.vue";
import CustomerSelect from "@/modules/customers/components/CustomerSelect.vue";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import {
  type CustomerData,
  type FirmData,
  InvoiceMovementType,
} from "@planetadeleste/vue-mc-gw";
import FormFieldDocId from "@/components/form/fields/DocidText.vue";
import SelectDocType from "@/components/form/fields/SelectDocType.vue";
import type { IDListKey } from "@/services/Utils";
import { assign, clone, delay, isNil, isString, set } from "lodash";
import FirmFieldAddress from "@/components/form/fields/FirmFieldAddress.vue";

@Component({
  components: {
    FirmFieldAddress,
    SelectDocType,
    FormFieldDocId,
    CustomerSelect,
    FirmPreviewDialog,
    CustomerFormDialog,
    CustomerFieldPreview,
  },
})
export default class CustomerRow extends Mixins(InvoiceMixin) {
  @Prop(Boolean) readonly readOnly!: boolean;
  @Prop(Object) readonly customerData!: Partial<CustomerData>;

  /**
   * Get true if invoice movement is type "VENTA" OR "DEVOLUCION"
   *
   * @returns {boolean}
   */
  get isSalesLike(): boolean {
    return [
      InvoiceMovementType.CODE_SALES,
      InvoiceMovementType.CODE_REFOUND,
    ].includes(this.iMovementType);
  }

  get sCustomerName() {
    return this.customerFirm?.dgi_denominacion || this.customerFirm?.name;
  }

  get sDocType(): IDListKey | string {
    return this.customerFirm?.doc_type || "";
  }

  set sDocType(sValue: IDListKey | string) {
    this.updateCustomerFirmData("doc_type", sValue);
  }

  get sDocId(): string | undefined {
    return this.customerFirm?.doc_id;
  }

  set sDocId(sValue: string | undefined) {
    this.updateCustomerFirmData("doc_id", sValue);
  }

  get isCi() {
    return this.sDocType === "ci";
  }

  get sName(): string {
    return this.customerFirm?.name || "";
  }

  set sName(sValue: string) {
    this.updateCustomerFirmData("name", sValue);
  }

  onSelectCustomer(obCustomer: Partial<CustomerData>) {
    this.updateCustomerFirmData({ name: "", doc_id: "", doc_type: "" });
    delay(() => {
      this.$emit("change:customer", obCustomer);
    }, 50);
  }

  onUpdateCustomerFirm(obCustomer: Partial<CustomerData>) {
    this.$emit("update:customer", obCustomer);
  }

  onOpenDialog() {
    this.$emit("open:dialog");
  }

  updateCustomerFirmData(obData: Partial<FirmData> | string, sValue?: any) {
    const obFirmData = clone(this.customerFirm);

    if (isString(obData) && !isNil(sValue)) {
      set(obFirmData, obData, sValue);
    } else {
      assign(obFirmData, obData);
    }

    this.obInvoice.set("customer_firm", obFirmData);
  }
}
</script>

<template>
  <div v-frag>
    <v-col key="customer_id" cols="12" lg="3" md="4" sm="6">
      <customer-field-preview
        v-if="customer && signed"
        :item="customer"
        :not-load="!isReceived"
      />
      <!--          :end-customer-on-init="!iMovementType || iMovementType !== 13"-->
      <customer-select
        v-else-if="iMovementType"
        :value="customerId"
        :create="!readOnly"
        :customer="isSalesLike"
        :provider="isPay"
        :disabled="readOnly"
        :foreign="iMovementType === 13"
        :require-dgi="requiredCustomerData"
        required
        @change="onSelectCustomer"
      >
        <template #selection="{ item }">
          {{ sCustomerName ?? item }}
        </template>

        <template #update>
          <customer-form-dialog
            v-if="!readOnly"
            :require-dgi="requiredCustomerData"
            :value="customerData"
            basic
            firm-only
            @save="onUpdateCustomerFirm"
            @open:dialog="onOpenDialog"
          />
          <firm-preview-dialog v-else :item="customerFirm" />
        </template>
      </customer-select>
    </v-col>

    <v-col cols="12" lg="2">
      <select-doc-type
        v-model="sDocType"
        :country-id="customerFirm?.country_id"
      />
    </v-col>

    <v-col cols="12" lg="2">
      <form-field-doc-id v-model="sDocId" :doc-type="sDocType" hide-load-btn />
    </v-col>

    <v-col cols="12" lg="5">
      <firm-field-address :firm="customerFirm" />
    </v-col>

    <v-col cols="12" lg="4" xl="3">
      <form-field-text
        v-model="sName"
        :label="isCi ? 'name' : 'fantasy.name'"
      />
    </v-col>
  </div>
</template>
